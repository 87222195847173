.list {
  min-width: 420px;
  max-width: 420px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .4);
  background-color: var(--secondary-color);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: min-content;
  max-height: -webkit-fill-available;
}

.list__container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 5px;
}


.input-task-container{
  display: flex;
  margin-bottom: 20px;
}


.input-task-container > input{
  flex: 1;
  margin: 4px;
  margin-right: 10px;
  padding: 14px;
  box-sizing: border-box;
  height: 50px;
  font-size: 1rem;
  background-color: var(--secondary-color);
  border: none;
  color: #bcc3ce;
  border-radius: 8px;
}


.input-task-container > input:focus {
  box-shadow: 0 0 0 1px #bcc3ce;
  outline: none;
  background-color: var(--primary-color);
}

.input-task-container > input:hover {
  background-color: var(--primary-color);
}

.input-task-container > button{
  font-weight: bold;
  font-size: 1.8rem;
  width: 80px;
  height: 50px;
  background-color: #2f3640;
  border: none;
  color: #bcc3ce;
}

.input-task-container > button:hover{
  cursor: pointer;
  background-color: #292f38;
}


.list__header{
  display: flex;
  margin-bottom: 10px;
}


.list__title{
  flex:1;
  background-color: transparent;
  border: none;
  color: var(--light-text-color);
  font-weight: bold;
  font-size: 1.2rem;
  padding: 10px;
  margin: 4px;
  cursor: pointer;
}
.list__title:focus{
  cursor: text;
  outline: none;
  box-shadow: 0 0 0 2px #C7CCDB;
  border-radius: 8px;
}

.list__header > button {
  width: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  font-size: 1.4rem;
  color: rgb(185, 0, 0);
}

.list__header > button:hover {
  background-color: var(--primary-color);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d4453;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #454d5e;
}