.container__field{
  display: flex;
  padding: 18px;
  font-size: 1.2rem;
  align-items: center;
  background-color: var(--primary-color);
  margin-bottom: 10px;
  border-radius: 10px;
}

.container__field:hover{
  background-color: #292f38;
}

.container__field-content{
  flex:1;
  word-break: break-all;
  margin-right: 10px;
}


.checked-field > .container__field-content{
  text-decoration-line: line-through;
  color:#7f8fa6;
}

.field__right{
  display: flex;
  justify-content: center;
  align-items: center;
}

.field__right button{
  background-color: #c23616;
  border: none;
  width: 25px;
  height: 25px;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 8px;
}

.checkbox {
  width: 25px;
  height: 25px;
}
